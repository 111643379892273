@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (max-width: 990px) {
  .site-layout {
    margin-left: 0px;
  }
}
@media screen and (max-width: 764px) {
  .site-layout {
    height: fit-content;
  }
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
  height: 100%;
  margin-bottom: 0px;
}
a {
  color: black;
}
.login-wrapper {
  background-color: #fff;
  width: 400px !important;
  padding-block: 40px;
  min-height: 300px !important;
  margin: 50px auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  box-shadow: 1px 1px 5px lightgray;
}
.login-wrapper .logo {
  width: 40% !important;
}
.login-wrapper .input-wrapper {
  width: 60%;
  margin-bottom: 30px;
}
.login-wrapper .login-register-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 13px;
}
.login-wrapper .login-register-wrapper span {
  cursor: pointer;
}
.login-wrapper .login-register-wrapper span:hover {
  color: black;
  text-decoration: underline;
}
.menu {
  height: 100vh;
  position: fixed;
  overflow: scroll;
}
@media screen and (max-width: 990px) {
  .menu {
    z-index: 10;
    overflow: visible;
  }
}
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}
.sum-flags-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.menu-button-wrapper {
  margin: 0 0 15px 15px;
}
.ant-menu-title-content {
  color: black;
}
.logo {
  height: 75px !important;
  margin: 16px !important;
}

.small-logo {
  height: 75px !important;
  margin: 16px !important;
  margin-top: 29px !important;
  margin-bottom: 4px !important;
}

.flaglist-description {
  max-width: 85%;
}
@media screen and (max-width: 764px) {
  .flaglist-description {
    max-width: 100%;
  }
}
@media screen and (max-width: 764px) {
  .flaglist-button {
    margin-top: 15px;
  }
}
.table-row-cursor {
  cursor: pointer;
}
.ant-table-cell a:hover {
  text-decoration: underline;
  color: black;
}
@media screen and (max-width: 764px) {
  .ant-table-wrapper {
    overflow: scroll;
  }
}
.datePicker {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
.datePicker input {
  padding: 3px 10px;
  border: 1px solid lightgrey;
  border-radius: 1px;
  margin: 0 5px 5px 0;
}
@media screen and (max-width: 400px) {
  .datePicker {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .datePicker input {
    margin: 5px 0;
  }
  .datePicker input:first-of-type {
    margin: 0;
  }
}
.range-picker {
  margin-bottom: 20px;
  width: 240px;
  /* width: 20%; */
  margin-left: 80%;
}
.table-edit-link {
  color: grey;
}
.table-edit-link :hover {
  color: black;
}
.single-page-button {
  margin-top: 40px;
}
.createcase-divider {
  display: none;
}
@media screen and (max-width: 764px) {
  .createcase-divider {
    display: block;
  }
}
@media screen and (max-width: 764px) {
  .createcase-wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 764px) {
  .createcase-flags {
    display: flex;
    flex-direction: column;
  }
}
.case-column:first-of-type {
  border-right: 1px solid lightgrey;
}
@media screen and (max-width: 764px) {
  .case-wrapper {
    display: flex;
    flex-direction: column;
  }
  .case-wrapper .case-column {
    min-width: 100%;
    border-right: 0;
  }
}
.decision-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  gap: 6px;
  align-items: center;
}

.decision-wrapper-ibic {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
  gap: 6px;
  align-items: center;
}
.decision-wrapper div {
  min-width: 25%;
}

.decision-wrapper-edit {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr max-content max-content max-content;
  gap: 6px;
}

/* rid-cols-[1fr_1fr_1fr_1fr_max-content_max-content_max-content] */
.decision-wrapper-edit-other {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr max-content max-content max-content;
  gap: 6px;
}

.decision-wrapper-edit-ibic {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr max-content max-content max-content;
  gap: 6px;
}

.decision-wrapper-edit-ibic-other {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr max-content max-content max-content;
  gap: 6px;
}

.decision-popover {
  width: 400px;
}
.decision-calculate-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.decision-calculate-save {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.decision-calculate-save button {
  margin-top: 0;
}
.add-user-button {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}
.table-edit-link {
  border: 0;
  background: 0;
  cursor: pointer;
}
.table-edit-link:hover {
  color: black;
}
.newuser-input-wrapper {
  width: 100%;
}
.newuser-input-wrapper .input-wrapper-flex {
  display: flex;
}
.newuser-input-wrapper .input-block {
  display: block;
}
.newuser-input-wrapper input,
.newuser-input-wrapper .select {
  width: 300px;
  min-width: 350px;
}
.newuser-input-wrapper p {
  margin: 15px 0 0 0;
}

.ant-form-item {
  padding: 4px;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
}
.graph-wrapper {
  display: flex;
  justify-content: space-between;
}
.graph {
  margin-top: 5%;
}
.footnote {
  position: relative;
  bottom: 1ex;
  font-size: 80%;
}

.footnote-normal-text {
  position: relative;
  bottom: 1ex;
  font-size: 100%;
}

.ant-select-selection-placeholder {
  color: black !important;
}
.button-wrapper {
  display: flex;
  justify-content: space-between;
}
.graph-wrapper {
  display: flex;
  justify-content: space-between;
}
.graph {
  margin-top: 5%;
}
.footnote {
  position: relative;
  bottom: 1ex;
  font-size: 80%;
}

.ant-select-selection-placeholder {
  color: black !important;
}

.ant-layout-content {
  margin: 0px 8px 0px;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.ant-table-column-title {
  font-weight: 600 !important;
}
.ant-layout-content {
  margin: 0px 0px 0px 10px !important;
}

.ant-table-thead > tr > th {
  height: 45px !important;
  padding: 8px 12px !important;
  align-items: center !important;
}

.ant-picker-input > input::placeholder {
  color: black !important;
}

.ant-menu-item ant-menu-item-selected ant-menu-item-only-child {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.ant-menu-inline {
  margin-top: 0px !important;
}

.ant-menu-vertical {
  border-right: 0px !important;
}

.button-no-hover:hover {
  background: rgba(0, 0, 0, 0) !important;
}

.ant-menu-inline {
  border-right: none !important;
}

.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-title .anticon {
  min-width: 16px !important;
  font-size: 16px !important;
}

.table-pagination-hidden {
  position: absolute;
  bottom: 100%;
  right: 0;
}

.table-pagination-hidden .ant-pagination-prev,
.table-pagination-hidden .ant-pagination-next,
.table-pagination-hidden .ant-pagination-item {
  display: none;
}

.timelineContent {
  margin-top: 20px !important;
}

.benchmarking-navigation > button::after {
  --antd-wave-shadow-color: transparent !important;
}
